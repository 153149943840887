@charset "UTF-8";

@import 'ls-theme/variables';

@import 'ls-theme/ls-navbar';
// @import 'ls-theme/ls-sidebar-main-panel';
// @import 'ls-theme/ls-new-sidebar-main-panel';
@import 'ls-theme/ls-dropdown';
@import 'ls-theme/ls-tooltip';
@import 'ls-theme/ls-badge';
@import 'ls-theme/ls-deckGl';
@import 'ls-theme/ls-page-header';
@import 'ls-theme/ls-modal';
@import 'ls-theme/ls-react-select';
@import 'ls-theme/ls-buttons';
@import 'global.scss';
@import '../scss/sightline-theme/variables.scss';

.cursor-pointer {
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}

.ls-disabled {
  cursor: not-allowed;
  opacity: 0.7;
  color: grey;
  :hover {
    color: grey !important;
  }
}

.btn-disabled {
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.mx-outside {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.theme-dark-gray {
  color: $theme-dark_grey;
}

.theme-light-gray {
  color: $theme-light_grey;
}

.theme-gray {
  color: $theme-grey;
}

.theme-anchor-color {
  color: $slineLightPurple;
}
.printTable tbody tr .setFooter {
  background: #3e4b5b !important;
  color: #ffffff !important;
  -webkit-print-color-adjust: exact;
}

.printTable tbody tr td.printfirstColor {
  background: #f6f9ff !important;
  -webkit-print-color-adjust: exact;
}

.printTable tbody tr td.printsecondColor {
  background: #ffffff !important;
  -webkit-print-color-adjust: exact;
}

.printTable tbody tr td.printthirdColor {
  background: #fdfaf3 !important;
  -webkit-print-color-adjust: exact;
}

.side-link {
  opacity: 0.5;
  transition-duration: 0.5s;
  transition-property: all;
  text-decoration: none;
  color: #fff;
  display: inline-block;
}

.side-link:hover,
.side-link:focus {
  color: #fff;
  opacity: 1;
  text-decoration: none;
}

.side-link-active {
  opacity: 1;
}

.miniSbHover {
  background: rgba(62, 75, 91, 0.1);
  color: rgba(256, 256, 256, 1);
  border-radius: 10px;
  transition-property: background-color;
  transition-duration: 0.25s;
  float: left;
  font-size: 25px;
}

.miniSbHover:hover,
.miniSbHover:focus {
  color: rgba(256, 256, 256, 1);
  border-radius: 10px;
  background: rgba(62, 75, 91, 0.2);
  float: left;
  font-size: 25px;
}

.videoBox button {
  border: 0px !important;
  padding: 0px !important;
}

.ls-no-data {
  min-height: 330px;
}

// #overlay {
//   position: fixed;
//   display: none;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0,0,0,0.3);
//   z-index: 2;
//   cursor: pointer;
// }
//
// .signal {
//     border: 5px solid #072164;
//     border-radius: 30px;
//     height: 30px;
//     width: 30px;
//     position: fixed;
//     top:50%;
//     left: 50%;
//     animation: pulsate 1s ease-out;
//     animation-iteration-count: infinite;
// }

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.my-input {
  background-color: #ffffff;
}

.beta-tag-span {
  text-transform: uppercase;
  position: relative;
  left: 10px;
  top: -2px;
  font-size: 12px;
  background: #e4e0e0;
  border: 1px solid #e4e0e0;
  color: #9e9898;
  border-radius: 3px;
  padding: 3px;
}
