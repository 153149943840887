@import '../sightline-theme/variables.scss';
.ls-select {
  .ls-select__control {
    background: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 0px 10px -3px rgba(0, 0, 0, 0.15);
  }

  .ls-select__indicator-separator {
    display: none;
  }

  .ls-select__menu {
    .ls-select__option--is-selected {
      background-color: #396fc9;
    }

    .ls-select__option--is-focused:not(.ls-select__option--is-selected) {
      background-color: #f8f9fa;
    }

    .ls-select__option:not(.ls-select__option--is-selected):hover {
      background-color: #f8f9fa;
    }
  }
}

.ls-ui-select {
  .ls-ui-select__control {
    border: 1px solid #e3e9f4;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: none;
  }

  .ls-ui-select__control.ls-ui-select__control--is-focused {
    border-color: $slinePurple !important;
  }

  .ls-ui-select__indicator-separator {
    display: none;
  }

  .ls-ui-select__menu {
    .ls-ui-select__option--is-selected {
      background-color: $slinePurple;
    }

    .ls-ui-select__option--is-focused:not(.ls-ui-select__option--is-selected) {
      background-color: #f8f9fa;
    }

    .ls-ui-select__option:not(.ls-ui-select__option--is-selected):hover {
      background-color: #f8f9fa;
    }
  }
}
