.theme-color-p {
  color: $theme_color_p;
}

.theme-color-s {
  color: $theme_color_s;
}

.theme-color-t {
  color: $theme_color_t;
}
