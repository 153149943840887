:root {
  --white-50: #d0d5dd;
  --white-500: #f6f9ff;
  --white-1000: #ffffff;

  --gray-50: #e2e2e2;

  --red-100: #ff4843;

  --blue-50: #3f74cb;
  --blue-100: #0655d3;
}
