.ls-badge-group {
  position: absolute;
  top: 6px;
  right: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.0607733);
  border-radius: 24px;
  z-index: 1;
  padding: 6px 10px;
}
