.noselect,
th {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.table {
  .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .img-row {
    max-width: 60px;
    width: 60px;
  }

  .form-check {
    margin: 0;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: 300;
  }

  .card-tasks .card-body & {
    margin-bottom: 0;

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  > thead > tr > th {
    //    font-size: 13px;
    //font-weight: $font-weight-bold;
    letter-spacing: 0.2px;
    padding: 13px 20px;
    //    text-transform: capitalize;
    border: 1px;
    //    color: white;
    //    background: $theme-dark_grey;
    cursor: pointer;
    //text-align: center;
  }

  > thead {
    border-radius: 5px;
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }

  //> thead > tr > th,
  > tbody > tr > th,
   > tfoot > tr > th,
   //> thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td {
    padding: 10px 20px;
    vertical-align: middle;
  }

  > thead > tr > th:first-child {
    border-top-left-radius: 5px;
  }

  > thead > tr > th:last-child {
    border-top-right-radius: 5px;
  }

  > thead > tr > th:last-child,
  > tbody > tr > td:last-child {
    text-align: left;
  }

  .th-description {
    max-width: 150px;
  }
  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }
  .td-total {
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  > tbody > tr {
    position: relative;
  }
}

.table-shopping {
  > thead > tr > th {
    font-size: $font-size-h6;
    text-transform: uppercase;
  }
  > tbody > tr > td {
    font-size: $font-paragraph;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }
  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.5em;
    small {
      color: $dark-gray;
      font-size: 0.75em;
      font-weight: $font-weight-light;
    }
  }
  .td-number {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
  }
  .td-name {
    min-width: 200px;
  }
  .td-number {
    text-align: right;
    min-width: 170px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

.table-responsive {
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive {
  margin-bottom: 30px;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.test:after {
  content: '\2807';
  font-size: 20px;
}

@media screen and (max-width: 1151px) and (min-width: 1024px) {
  .attr-table,
  .my-audience {
    overflow-x: scroll;
    .table {
      min-width: 1080px !important;

      > thead > tr > th {
        padding: 13px 12px;
        .table-sort {
          position: relative;
          vertical-align: top;
        }
      }

      > tbody > tr > th,
      > tbody > tr > td {
        padding: 10px 12px;
        vertical-align: middle;
      }
    }
  }
}

@media screen and (max-width: 1330px) and (min-width: 1152px) {
  .attr-table > .table,
  .my-audience > .table {
    > thead > tr > th {
      letter-spacing: 0.2px;
      padding: 13px 8px;
      // padding: 0.3rem 0.3rem;
      border: 1px;
      cursor: pointer;
      .table-sort {
        margin-left: 2px;
      }
    }

    //> thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    //> thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      padding: 10px 8px;
      // padding: 0.68rem 0.5rem;
      vertical-align: middle;
    }

    > tbody > tr > td:last-child {
      padding-right: 5px;
    }
  }
}
