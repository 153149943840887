@import 'sightline-theme/variables.scss';
.channel-type {
  display: flex;
  align-items: center;
  border: 2px solid #89929e;
  box-sizing: border-box;
  border-radius: 6px;
  width: 48%;
  // padding: 8px;
  cursor: pointer;
  font-size: 12px;
  color: #89929e;

  p {
    font-weight: bold;
    letter-spacing: 1.164px;
  }

  small {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.3px;
  }
}

.channel-type:hover {
  border: 2px solid #396fc9;
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
}

.channel-type.selected-type {
  border: 2px solid $slinePurple;
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
}

.aud-partner-pop {
  .popover-header {
    margin-top: 5px !important;
    padding: 0.5rem 0.75rem !important;
  }
}

.ls-new-popover {
  .popover.show.bs-popover-auto {
    width: 240px;
    left: 0px !important;
    padding: 0;
    border-radius: 0.3rem;
  }
}

.chart-card {
  min-height: 320px;
  flex-grow: 1;
}
