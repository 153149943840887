@import '~@fontsource/rubik/300.css';
@import '~@fontsource/rubik/400.css';
@import '~@fontsource/rubik/500.css';
@import '~@fontsource/rubik/600.css';
@import '~@fontsource/rubik/700.css';

// Colors
// $blue-primary:                  #0654D4;
// $blue-dark:                     #002687;
// $blue-darker:                   #081536;
//
// $grey-lighter:                  #F2F4F8;
// $grey-light:                    #E3E9F4;
// $grey:                          #89929E;
// $grey-dark:                     #505C6C;

$lsWhite: #ffffff;
$lsDarkBlue: #081536;
$lsPurple: #2a0c66;

// Sidebar variables
$lsSidebarWidth: 260px;
$lsMiniSidebarWidth: 64px;
