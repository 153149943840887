.btn {
  font-weight: 500;
  border-radius: 8px;
  font-size: 0.9rem;

  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
