$slineDarkPurple: #1e0a44;
$slinePurple: #2a0c66;
$slineLightPurple: #726fdd;

$slineDarkPink: #8e193d;
$slineDarkPink1: #a02747;
$slinePink: #da4761;
$slineLightPink: #ff5f89;

$slineBlack: #000000;
$slineLightBlack: #232226;

$slineWhite: #ffffff;

$slineGray: #b5b5b5;

$slineOrange: #dc5c51;
$slineOrangeLight: #ff6f61;

$slineRed: #af2e2e;
