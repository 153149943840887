@import '../../../assets/scss/sightline-theme/variables.scss';

.link {
  color: #535353;
}
.link:hover {
  color: $slinePurple;
}

.login-btn {
  background-color: #da4761 !important;
}
