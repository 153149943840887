.ls-navbar {
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  height: 55px;
  margin-bottom: 0;
  box-shadow: 0px 2px 2px #ddd;
}

.nav-dropdown {
  // max-height: 210px;
  // overflow-y: scroll;
  border-radius: 4px;
}
