@import 'paper-dashboard/variables';
@import '../scss/sightline-theme/variables.scss';

.channelName {
  padding: 0px 10px 14px 10px;
  font-size: 14px;
  text-align: center;
}

.budgetNames {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.97px;
  color: #89929e;
  //padding: 6px 4px;
  text-transform: uppercase;
  font-weight: 700;
}

.custom-list-item {
  max-height: 320px;
  overflow-y: scroll;
  z-index: 10;
  position: absolute;
}

.resultForm {
  .form-check {
    margin: 0px;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 0px;
  }
  .form-check-input {
    position: unset;
    margin-right: 0.5rem;
    margin-left: 0;
  }
}

.selectall {
  color: #3c88fb;
  cursor: pointer;
}

.checkboxPlaces {
  color: #3e4b5b;
  letter-spacing: 0.23px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
}

.checkboxPlaces:hover {
  background: #fafbfe !important;
  color: $slinePurple;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
}

.right-aligned-date {
  .rdtPicker {
    right: 0;
  }
  .rdtPicker:before {
    right: 10px;
    left: auto;
  }
  .rdtPicker:after {
    right: 10px;
    left: auto;
  }
}

.datepickerpadd {
  padding: 10px;
}

.placeCotent {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #89929e;
  opacity: 0.7;
  vertical-align: middle;
  position: absolute;
  top: 200px;
}

.placeDiscription {
  padding: 130px 66px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #89929e;
  opacity: 0.7;
}

.placeDiscription {
  //padding: 332px 66px 0px 66px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #89929e;
  opacity: 0.7;
}

/* loader */

.loader {
  border: 9px solid #f3f3f3;
  border-radius: 50%;
  border-top: 9px solid #396fc9;
  border-bottom: 9px solid #396fc9;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.circleName {
  font-size: 10px;
  padding: 11px 3px;
  color: #396fc9;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderadj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalTracker h5.modal-title {
  text-align: left;
  font-size: 29px;
  line-height: 29px;
  color: #3e4b5b;
}

.modalTracker .modal-header .close {
  padding: 1rem 1rem;
  margin: -2.7rem -1rem -1rem auto;
  color: #3e4b5b;
  font-size: 30px;
}

.trackerbox {
  word-break: break-all;
  background: ghostwhite;
  border-radius: 4px;
  text-align: justify;
  //padding: 30px 15px;
  letter-spacing: 1px;
  color: $theme-dark_grey;
}

.trackerboxbig {
  background: #f2f4f8;
  border-radius: 4px;
  text-align: center;
  padding: 76px 20px;
  color: #89929e;
  opacity: 0.7;
}

label.trackerLables {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.1px;
  color: #3e4b5b;
  font-weight: 500;
}

.setbuttonTracker {
  margin: 12px 35px;
}

.channelLogoBox {
  border-radius: 6px;
  width: 105px;
  height: 105px;
  border: 2px solid white;
}

.channelBox {
  background: #ffffff;
  cursor: pointer;
}

.activeChannel {
  border: 2px solid $slinePurple;
  background: #f5faff;
}

.channelBoxDisabled {
  pointer-events: none;
}

.disabledChannel {
  //border: 2px solid #999;
  //background: #999;
  pointer-events: none;
  opacity: 0.5;
}

.brandSearchResultGroup {
  max-height: 243px;
  overflow-y: scroll;
  z-index: 12;
  position: absolute;
  width: 92%;
}

.brandSearchResults {
  cursor: pointer !important;
  background: #ffffff !important;
  color: $theme-dark_grey;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.brandSearchResults:hover {
  cursor: pointer !important;
  background: #fafbfe !important;
  color: $slinePurple;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.23px;
}

.brandSearchResultSelected {
  cursor: pointer !important;
  background: #fafbfe !important;
  color: $slinePurple;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.23px;
}

.selectAll {
  cursor: pointer !important;
  color: #3c88fb;
  font-weight: 700;
  font-size: 10px;
}

.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background-color: rgba(255,255,255,0.5);
  background-color: rgba(242, 244, 248, 0.5);
  z-index: 9999;
  //display:none;
  transition-duration: 150ms;
  transition-property: display;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 42%;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-color: $theme-grey;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.modal-tracker {
  .modal-dialog {
    max-width: 50%;
    width: 50%;
    padding-top: 20px;
  }

  .modal-header {
    text-align: left;
    border-bottom: none;
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .modal-content {
    border-radius: 8px !important;
    // width: 120%;
    // left: -100px;
    // top: -50px;
  }

  .modal-body {
    padding: 1rem !important;
    //background-color: #F2F4F8;
  }
}
