.dropdown-menu {
  margin-top: 7px;
}

.dropdown-menu-right {
  right: 0px;
  left: auto;
}

button.stick.dropdown-item {
  color: #396fc9;
  border-left: 5px solid #396fc9;
  font-weight: 700;
  padding-left: 9px !important;
  background: #f8f9fa;
}

.dropdown-item:focus {
  background-color: #fff;
}

.dropdown-item {
  cursor: pointer;
}

li.mx-2.dropdown .ls-name span.truncate {
  width: 156px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li.mx-2.dropdown.ls-name {
  color: #396fc9;
}

a.pr-2.text-capitalize.font-weight-bold.ls-name.dropdown-toggle.nav-link:hover {
  background: #274e8f;
  color: #fff;
}

li.mx-2.dropdown.show.nav-item .ls-name.dropdown-toggle.nav-link {
  background: #274e8f;
  color: #fff;
}

.dropdown-toggle::after {
  vertical-align: middle;
}
