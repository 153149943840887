@import '../sightline-theme/variables.scss';
.ls-new-modal.modal-dialog {
  padding-top: 0;
}

.ls-new-modal {
  .modal-content {
    border-radius: 8px;
  }

  .modal-footer {
    padding: 0.75rem;
  }

  .modal-body {
    padding: 20px;
  }
}

.ls-new-modal {
  span {
    margin-right: auto;
  }
  .modal-title {
    font-weight: 600;
    font-size: 29px;
    line-height: 29px;
    color: #3e4b5b;
    display: flex;
  }

  .header-icon {
    background-color: $slinePink;
    border-radius: 4px;
    color: #fff;
  }

  .header-icon-primary {
    height: 29px;

    path {
      fill: $slinePurple;
    }
  }

  .header-icon-attr {
    height: 29px;

    path {
      stroke: #396fc9;
    }

    path:nth-child(3) {
      fill: #396fc9;
    }
  }
}

.activation-modal.modal-dialog {
  max-width: 576px;
}

.audience-modal.modal-dialog {
  max-width: 800px;
}

.attribution-modal.modal-dialog {
  max-width: 1100px;
}

.audience-modal {
  .modal-body {
    background-color: #ffffff;
  }
}
