.leadform-modal.modal-dialog {
  max-width: 438px;
  padding-top: 0;
}

.leadform-title {
  font-weight: 600;
  font-size: 29px;
  line-height: 29px;
  color: #3e4b5b;
}

.leadform-text {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.3px;
  color: #3e4b5b;
}

.leadform-btn-group {
  .btn {
    flex-basis: 48%;
  }
}

.leadform-image {
  position: relative;
  height: 106px;
  width: 110px;

  .human-shape {
    position: absolute;
    left: 0;
    top: 10px;
    width: 60px;
    height: 78px;
    z-index: 1;
  }

  .lock-shape {
    position: absolute;
    top: 0;
    right: 0;
    width: 68px;
    height: 74px;
  }

  .rocket-shape {
    position: absolute;
    left: 4px;
    bottom: 0;
    width: 44px;
    height: 40px;
  }
}
