@import '../sightline-theme/variables.scss';
.smallBar {
  height: 5px;
  border-radius: 5px;
  width: 25px;
  background: $slinePurple;
  float: left;
  z-index: 999;
  margin-top: -2px;
}

.longBar {
  height: 1px;
  border-radius: 5px;
  background-color: #dee2e7;
}
