@import '../../assets/scss/ls-theme/variables';
@import '../../assets/scss/sightline-theme/variables.scss';

.ls-ui-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: $lsSidebarWidth;

  .sidebar-icons {
    background-color: $lsPurple;
    width: $lsMiniSidebarWidth;

    .side-svg-icon {
      cursor: pointer;
      &:hover {
        transform: scale(1.08);
      }
    }

    .sidebar-scroll {
      overflow-y: auto;
      overflow-x: hidden;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    .country-badge {
      margin: 6px;
      padding: 5px;
      text-align: center;
      background: #010510;
      border-radius: 23px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.164px;
      color: #ffffff;
    }
  }

  .sidebar-pages {
    background-color: #f6f6f7;
    flex-grow: 1;
    // box-shadow: inset 0px 4px 40px rgba(0, 0, 0, 0.15);
    // box-shadow: inset -10px 0px 18px 0px rgba(0, 0, 0, 0.15);
    box-shadow: inset -12px 0px 16px 0px #e4e4e5;

    .sidebar-page-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #142140;
      padding-bottom: 1.13rem !important;
    }

    .sidebar-page-links {
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.4px;
      color: #142140;
      display: block;
      padding-top: 12px;
      padding-bottom: 12px;
      text-decoration: none;

      &.ls-active-link {
        color: $slinePurple;
      }
    }
  }

  .selected-side {
    width: 64px;
    height: 64px;
    overflow: hidden;

    * {
      fill: $lsDarkBlue !important;
      background-color: #f6f6f7 !important;
    }
  }
}

.sidenav-popover.popover {
  transition: opacity 150ms ease-in;
  z-index: 1000;
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;

  .account-list {
    max-height: 232px;
    overflow-y: auto;
  }
}

.ls-new-side-pop {
  position: absolute;
  left: 64px;
}

.fs-16 {
  font-size: 16px;
}
