@import '../../scss/sightline-theme/variables.scss';
/*           badges             */
.badge {
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: $font-size-mini;
  letter-spacing: 0.5px;
  line-height: 12px;
  //background-image: linear-gradient(-180deg,#fafbfc,$theme-light_grey 90%);
  border: 1px solid #2a0c66;
  text-decoration: none;
  color: $slinePurple;
  margin-bottom: 5px;
  transition-duration: 0.5s;
  transition-property: background, color;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #fff !important;
    background-color: $slinePurple;
    border: 1px solid $slinePurple;
    //background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
  }
  & + .badge {
    margin-left: 5px;
  }
}

.badge-active {
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: $font-size-mini;
  line-height: 12px;
  //background-image: linear-gradient(-180deg,#fafbfc,$theme-light_grey 90%);
  border: $border;
  color: #ffffff !important;
  margin-bottom: 5px;
  cursor: pointer;

  text-decoration: none;
  border-color: $slinePurple;
  background-color: $slinePurple;
  //background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
}

.badge-icon {
  padding: 0.4em 0.55em;
  i {
    font-size: 0.8em;
  }
}
.badge-default {
  @include badge-color($default-color);
}
.badge-primary {
  @include badge-color($primary-color);
}
.badge-info {
  @include badge-color($info-color);
}
.badge-success {
  @include badge-color($success-color);
}
.badge-warning {
  @include badge-color($warning-color);
}
.badge-danger {
  @include badge-color($danger-color);
}
.badge-neutral {
  @include badge-color($white-color);
  color: inherit;
}
.badge-theme-color-p {
  @include badge-color($slinePurple);
}
.badge-theme-color-s {
  @include badge-color($theme_color_s);
}
.badge-theme-color-t {
  @include badge-color($theme_color_t);
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  @include badge-hover-href($white-color, darken($primary-color, 3%));
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  @include badge-hover-href($white-color, darken($warning-color, 3%));
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  @include badge-hover-href($white-color, darken($info-color, 3%));
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  @include badge-hover-href($white-color, darken($danger-color, 3%));
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  @include badge-hover-href($white-color, darken($success-color, 3%));
}

.badge-default[href]:focus,
.badge-default[href]:hover {
  @include badge-hover-href($white-color, darken($default-color, 3%));
}
