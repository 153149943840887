.ls-tooltip {
  background-color: #262626 !important;
  color: #fff !important;
  min-width: 20px !important;
  padding: 8px 15px !important;
  font-size: 0.8rem !important;
  box-shadow: 0 0 0 !important;
}

.arrowColor {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-right: 15px solid #262626;
}

.ls-tooltip-map {
  position: absolute;
  word-break: break-all;
  border-radius: 6px;
  z-index: 2000;
}

.tooltip .arrow::before {
  display: none !important;
}

.tooltip {
  pointer-events: none;
}
