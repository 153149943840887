* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  /* font-family: "ProximaNova-Regular"; */
  line-height: 1.42857143;
}

h1,
h2,
h3,
h4,
h5,
p,
ul {
  margin: 0 !important;
  padding: 0 !important;
}
