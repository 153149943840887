.new-landing-page-wrapper {
  min-height: 100vh;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-card {
  width: 368px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    // border: 1px solid green;
    // -webkit-text-fill-color: green;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    // transition: background-color 5000s ease-in-out 0s;
  }
}

.ls-input-group.input-group {
  margin-bottom: 0;
  border: 0.6px solid #979dab;
  border-radius: 6px;

  * {
    border: none !important;
    outline: none !important;
    border-radius: 6px;
  }

  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text {
    color: #000000 !important;
    background-color: transparent !important;
  }
}

.ls-input-group.input-group.input-group-focus {
  border-color: #2b4fa2 !important;

  * {
    border: none !important;
  }
}

.ls-input-group.input-group + .error {
  color: red;
  font-size: 12px;
  height: 18px;
}
