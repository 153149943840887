@import '../../assets/scss/ls-theme/variables';

.new-main-page-wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  .main-content {
    float: right;
    position: relative;
    width: calc(100% - #{$lsSidebarWidth});
    min-height: 100%;
    background: #f2f4f8 !important;
    transition: all 0.35s ease;

    > .content {
      padding: 16px 24px 24px;
      min-height: 100vh;
    }
  }
}

.s-mini {
  .main-content {
    width: calc(100% - #{$lsMiniSidebarWidth});
    transition: all 0.35s ease;
  }
}
